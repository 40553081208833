// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';

// import all image files in a folder:
// via: https://github.com/rails/webpacker/blob/5-x-stable/docs/assets.md#link-in-your-rails-views
// eslint-disable-next-line no-undef
require.context('../images', true);

Rails.start();
ActiveStorage.start();
